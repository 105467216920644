function playVideoUsingHlsJs (src, video) {
  const hls = new window.Hls()
  hls.loadSource(src)
  hls.attachMedia(video)
  hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
    video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
  })
}

export function playVideoHls (video, src) {
  if (!video) {
    return
  }
  video.src = src
  const isM3u8 = /\.m3u8/i.test(src)

  if (!isM3u8) {
    video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
    return
  }

  if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
    return
  }
  if (window.Hls) {
    playVideoUsingHlsJs(src, video)
    return
  }
  const hlsScript = document.createElement('script')
  hlsScript.setAttribute(
    'src',
    'https://cdn.jsdelivr.net/npm/hls.js@latest'
  )
  hlsScript.onload = () => playVideoUsingHlsJs(src, video)
  document.head.appendChild(hlsScript)
}
