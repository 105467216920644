
import Nl2br from '~/mixins/nl2br.js'

export default {
  name: 'HeroContent',
  mixins: [Nl2br],
  inject: ['getProps'],
  props: {
    isInsideLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    p () {
      return this.getProps()
    },
    contentFlexVerticalJustify () {
      switch (this.p.alignVertically) {
        case 'middle':
          return 'center'
        case 'bottom':
          return 'center'
        case 'top':
          return 'flex-start'
        default:
          return 'flex-start'
      }
    },
    contentFlexHorizontalJustify () {
      switch (this.p.alignHorizontally) {
        case 'center':
          return 'center'
        case 'right':
          return 'flex-end'
        case 'left':
          return 'flex-start'
        default:
          return 'flex-start'
      }
    },
    displayInlineCta () {
      return (
        !this.p.ctaPushToggle ||
        // This makes sure that, if the CTA button and rest of the content overlap, we render the inline version
        this.p.alignVertically === 'bottom' ||
        // If mobile and split, always show inline
        (this.p.mobile && this.p.splitToggle)
      )
    }
  }
}
