
import HeroContent from './HeroContent'
import { playVideoHls } from '@/lib/play-video-hls'

export default {
  name: 'HeroController',
  components: {
    HeroContent
  },
  inject: ['getGeneralProps', 'getMobileProps', 'getDesktopProps'],
  provide () {
    return {
      getProps: () => this.p
    }
  },
  props: {
    mobile: Boolean
  },
  computed: {
    p () {
      return {
        ...this.getGeneralProps(),
        ...(this.mobile ? this.getMobileProps() : this.getDesktopProps()),
        mobile: this.mobile,
      }
    },
    splitColorOrFallback () {
      const { splitColor, splitColorHex, backgroundColor, backgroundColorHex } = this.p
      return {
        'background-color': (splitColorHex || splitColor) || (backgroundColorHex || backgroundColor)
      }
    },
    fallback () {
      const { backgroundColor, backgroundColorHex } = this.p
      if (!this.p.image) {
        return {
          'background-color': backgroundColorHex || backgroundColor
        }
      }
      return {}
    },
    imageFocusPoint () {
      // extract the first two values from the focus point if it exists
      // eg 349x722:350x723 -> 349, 722
      if (this.p.focus) {
        const focusX = this.p.focus?.split(':')?.[0]?.split('x')
        const focusY = this.p.focus?.split(':')?.[1]?.split('x')

        // since the focuspoints are based off of image's dimensions extract the width and height of the image
        //  eg "https://a.storyblok.com/f/111784/2500x1250/6cc1ffad8f/2494_home_desktop.jpg" > 2500, 1250
        const imageDimensions = this.p.image.match(/(\d+)x(\d+)/)
        const imageWidth = imageDimensions?.[1]
        const imageHeight = imageDimensions?.[2]

        // calculate the percentage of the focus point
        // eg 349 / 2500 = 0.1396
        const focusXPercentage = focusX?.[0] / imageWidth
        const focusYPercentage = focusY?.[1] / imageHeight

        // return the focus point as a percentage rounded to one decimal
        return `${Math.round(focusXPercentage * 1000) / 10}% ${Math.round(focusYPercentage * 1000) / 10}%`
      }
      return ''
    }
  },
  updated () {
    if (typeof window !== 'undefined' && this.p.videoSrc) {
      playVideoHls(this.$refs.videoElement, this.p.videoSrc)
    }
  },
}
