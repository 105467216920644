import { render, staticRenderFns } from "./HeroController.vue?vue&type=template&id=4cd82ccc&scoped=true"
import script from "./HeroController.vue?vue&type=script&lang=js"
export * from "./HeroController.vue?vue&type=script&lang=js"
import style0 from "./HeroController.vue?vue&type=style&index=0&id=4cd82ccc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd82ccc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/usr/src/app/components/responsive-image/ResponsiveImage.vue').default,Video: require('/usr/src/app/components/video/Video.vue').default,HeroContent: require('/usr/src/app/components/hero/HeroContent.vue').default,MpLink: require('/usr/src/app/components/mp-link/MpLink.vue').default})
